import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import AUTHENTICATION_EN from './assets/locales/en/authentication.json';
import AUTHENTICATION_DE from './assets/locales/de/authentication.json';
import AUTHENTICATION_ES from './assets/locales/es/authentication.json';
import AUTHENTICATION_FR from './assets/locales/fr/authentication.json';
import AUTHENTICATION_IT from './assets/locales/it/authentication.json';
import AUTHENTICATION_RO from './assets/locales/ro/authentication.json';

import COMMON_EN from './assets/locales/en/common.json';
import COMMON_DE from './assets/locales/de/common.json';
import COMMON_ES from './assets/locales/es/common.json';
import COMMON_FR from './assets/locales/fr/common.json';
import COMMON_IT from './assets/locales/it/common.json';
import COMMON_RO from './assets/locales/ro/common.json';

import CREDITDREQUEST_EN from './assets/locales/en/createCreditRequest.json'; 
import CREDITDREQUEST_IT from './assets/locales/it/createCreditRequest.json'; 

import CREATESTOCKCOUNT_EN from './assets/locales/en/createStockCount.json';
import CREATESTOCKCOUNT_IT from './assets/locales/it/createStockCount.json';

import CREATESTOCKCOUNTSCHEDULE_EN from './assets/locales/en/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_IT from './assets/locales/it/createStockCountSchedule.json';

import CREATETRANSACTION_EN from './assets/locales/en/createTransaction.json';
import CREATETRANSACTION_DE from './assets/locales/de/createTransaction.json';
import CREATETRANSACTION_ES from './assets/locales/es/createTransaction.json';
import CREATETRANSACTION_FR from './assets/locales/fr/createTransaction.json';
import CREATETRANSACTION_IT from './assets/locales/it/createTransaction.json';
import CREATETRANSACTION_RO from './assets/locales/ro/createTransaction.json';

import DRAWER_EN from './assets/locales/en/drawer.json';
import DRAWER_DE from './assets/locales/de/drawer.json';
import DRAWER_ES from './assets/locales/es/drawer.json';
import DRAWER_FR from './assets/locales/fr/drawer.json';
import DRAWER_IT from './assets/locales/it/drawer.json';
import DRAWER_RO from './assets/locales/ro/drawer.json';

import ERROR_PAGE_EN from './assets/locales/en/errorPage.json';
import ERROR_PAGE_IT from './assets/locales/it/errorPage.json';

import INVOICEDETAILS_EN from './assets/locales/en/invoiceDetails.json';
import INVOICEDETAILS_IT from './assets/locales/it/invoiceDetails.json';

import INVOICES_EN from './assets/locales/en/invoices.json';
import INVOICES_IT from './assets/locales/it/invoices.json';

import MANAGE_NOTIFICATIONS_EN from './assets/locales/en/manageNotifications.json';
import MANAGE_NOTIFICATIONS_IT from './assets/locales/it/manageNotifications.json';

import MANAGE_CONFIGURATIONS_EN from './assets/locales/en/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_IT from './assets/locales/it/manageConfigurations.json';

import MANAGE_ROLES_EN from './assets/locales/en/manageRoles.json';
import MANAGE_ROLES_IT from './assets/locales/it/manageRoles.json';

import MANAGE_USERS_EN from './assets/locales/en/manageUsers.json';
import MANAGE_USERS_IT from './assets/locales/it/manageUsers.json';

import MANAGE_CATEGORIES_EN from './assets/locales/en/manageCategories.json';

import MANAGETRANSACTIONS_EN from './assets/locales/en/manageTransactions.json';
import MANAGETRANSACTIONS_IT from './assets/locales/it/manageTransactions.json';

import ORDER_DETAILS_EN from './assets/locales/en/orderDetails.json';
import ORDER_DETAILS_DE from './assets/locales/de/orderDetails.json';
import ORDER_DETAILS_ES from './assets/locales/es/orderDetails.json';
import ORDER_DETAILS_FR from './assets/locales/fr/orderDetails.json';
import ORDER_DETAILS_IT from './assets/locales/it/orderDetails.json';
import ORDER_DETAILS_RO from './assets/locales/ro/orderDetails.json';

import ORDER_FORM_EN from './assets/locales/en/orderForm.json';
import ORDER_FORM_DE from './assets/locales/de/orderForm.json';
import ORDER_FORM_ES from './assets/locales/es/orderForm.json';
import ORDER_FORM_FR from './assets/locales/fr/orderForm.json';
import ORDER_FORM_IT from './assets/locales/it/orderForm.json';
import ORDER_FORM_RO from './assets/locales/ro/orderForm.json';

import ORDER_HISTORY_EN from './assets/locales/en/orderHistory.json';
import ORDER_HISTORY_DE from './assets/locales/de/orderHistory.json';
import ORDER_HISTORY_ES from './assets/locales/es/orderHistory.json';
import ORDER_HISTORY_FR from './assets/locales/fr/orderHistory.json';
import ORDER_HISTORY_IT from './assets/locales/it/orderHistory.json';
import ORDER_HISTORY_RO from './assets/locales/ro/orderHistory.json';

import PRODUCT_DETAILS_EN from './assets/locales/en/productDetails.json';
import PRODUCT_DETAILS_DE from './assets/locales/de/productDetails.json';
import PRODUCT_DETAILS_ES from './assets/locales/es/productDetails.json';
import PRODUCT_DETAILS_FR from './assets/locales/fr/productDetails.json';
import PRODUCT_DETAILS_IT from './assets/locales/it/productDetails.json';
import PRODUCT_DETAILS_RO from './assets/locales/ro/productDetails.json';

import PRODUCTS_EN from './assets/locales/en/products.json';
import PRODUCTS_DE from './assets/locales/de/products.json';
import PRODUCTS_ES from './assets/locales/es/products.json';
import PRODUCTS_FR from './assets/locales/fr/products.json';
import PRODUCTS_IT from './assets/locales/it/products.json';
import PRODUCTS_RO from './assets/locales/ro/products.json';

import PURCHASEORDERDETAILS_EN from './assets/locales/en/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_IT from './assets/locales/it/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_DE from './assets/locales/de/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_ES from './assets/locales/es/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_FR from './assets/locales/fr/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_RO from './assets/locales/ro/purchaseOrderDetails.json';

import QUICK_ORDER_EN from './assets/locales/en/quickOrder.json';
import QUICK_ORDER_DE from './assets/locales/de/quickOrder.json';
import QUICK_ORDER_ES from './assets/locales/es/quickOrder.json';
import QUICK_ORDER_FR from './assets/locales/fr/quickOrder.json';
import QUICK_ORDER_IT from './assets/locales/it/quickOrder.json';
import QUICK_ORDER_RO from './assets/locales/ro/quickOrder.json';

import RECIPE_EN from './assets/locales/en/recipe.json';
import RECIPE_IT from './assets/locales/it/recipe.json';

import RECIPEUPDATES_EN from './assets/locales/en/recipeUpdates.json';
import RECIPEUPDATES_DE from './assets/locales/de/recipeUpdates.json';
import RECIPEUPDATES_ES from './assets/locales/es/recipeUpdates.json';
import RECIPEUPDATES_FR from './assets/locales/fr/recipeUpdates.json';
import RECIPEUPDATES_IT from './assets/locales/it/recipeUpdates.json';
import RECIPEUPDATES_RO from './assets/locales/ro/recipeUpdates.json';

import STOCKCOUNT_EN from './assets/locales/en/stockCount.json';
import STOCKCOUNT_IT from './assets/locales/it/stockCount.json';

import STOCK_COUNT_REVIEW_EN from './assets/locales/en/stockCountReview.json';
import STOCK_COUNT_REVIEW_IT from './assets/locales/it/stockCountReview.json';

import STOCK_COUNT_SCANNING_EN from './assets/locales/en/stockCountScanning.json';
import STOCK_COUNT_SCANNING_IT from './assets/locales/it/stockCountScanning.json';

import STOCKCOUNTZONES_EN from './assets/locales/en/stockCountZones.json';
import STOCKCOUNTZONES_IT from './assets/locales/it/stockCountZones.json';

import STOCK_TRANSFERS_EN from './assets/locales/en/stockTransfers.json';
import STOCK_TRANSFERS_IT from './assets/locales/it/stockTransfers.json';

import STOCK_TRANSFERS_DETAILS_EN from './assets/locales/en/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_IT from './assets/locales/it/stockTransfersDetails.json';

import UOMCONVERSIONS_EN from './assets/locales/en/uomConversions.json';
import UOMCONVERSIONS_DE from './assets/locales/de/uomConversions.json';
import UOMCONVERSIONS_ES from './assets/locales/es/uomConversions.json';
import UOMCONVERSIONS_FR from './assets/locales/fr/uomConversions.json';
import UOMCONVERSIONS_IT from './assets/locales/it/uomConversions.json';
import UOMCONVERSIONS_RO from './assets/locales/ro/uomConversions.json'; 

import WASTECHECKSHEET_EN from './assets/locales/en/wasteChecksheet.json';
import WASTECHECKSHEET_DE from './assets/locales/de/wasteChecksheet.json';
import WASTECHECKSHEET_ES from './assets/locales/es/wasteChecksheet.json';
import WASTECHECKSHEET_FR from './assets/locales/fr/wasteChecksheet.json';
import WASTECHECKSHEET_IT from './assets/locales/it/wasteChecksheet.json';
import WASTECHECKSHEET_RO from './assets/locales/ro/wasteChecksheet.json';

import SCHEDULED_REPORTS_IT from './assets/locales/it/scheduledReports.json';
import SCHEDULED_REPORTS_EN from './assets/locales/en/scheduledReports.json';


const resources = {
  en: {
    authentication: AUTHENTICATION_EN,
    common: COMMON_EN,
    createCreditRequest : CREDITDREQUEST_EN,
    createStockCount: CREATESTOCKCOUNT_EN,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_EN,
    createTransaction: CREATETRANSACTION_EN, 
    drawer: DRAWER_EN,
    errorPage: ERROR_PAGE_EN,
    invoiceDetails: INVOICEDETAILS_EN,
    invoices: INVOICES_EN,
    manageNotifications: MANAGE_NOTIFICATIONS_EN,
    manageConfigurations: MANAGE_CONFIGURATIONS_EN,
    manageCategories: MANAGE_CATEGORIES_EN,
    manageRoles: MANAGE_ROLES_EN,
    manageTransactions: MANAGETRANSACTIONS_EN,
    manageUsers: MANAGE_USERS_EN,
    orderDetails: ORDER_DETAILS_EN,
    orderForm: ORDER_FORM_EN,
    orderHistory: ORDER_HISTORY_EN,
    products: PRODUCTS_EN,
    productDetails: PRODUCT_DETAILS_EN,
    purchaseOrderDetails: PURCHASEORDERDETAILS_EN,
    quickOrder: QUICK_ORDER_EN,
    recipe: RECIPE_EN,
    recipeUpdates: RECIPEUPDATES_EN,
    stockCount: STOCKCOUNT_EN,
    stockCountReview: STOCK_COUNT_REVIEW_EN,
    stockCountScanning: STOCK_COUNT_SCANNING_EN,
    stockCountZones: STOCKCOUNTZONES_EN,
    stockTransfers: STOCK_TRANSFERS_EN,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_EN,
    uomConversions: UOMCONVERSIONS_EN,  
    scheduledReports: SCHEDULED_REPORTS_EN,
    wasteChecksheet: WASTECHECKSHEET_EN,  
  },
  de: {
    authentication: AUTHENTICATION_DE,
    common: COMMON_DE,
    createTransaction: CREATETRANSACTION_DE,
    drawer: DRAWER_DE,
    orderDetails: ORDER_DETAILS_DE,
    orderForm: ORDER_FORM_DE,
    orderHistory: ORDER_HISTORY_DE,
    productDetails: PRODUCT_DETAILS_DE,
    products: PRODUCTS_DE,
    quickOrder: QUICK_ORDER_DE,
    recipeUpdates: RECIPEUPDATES_DE,
    uomConversions: UOMCONVERSIONS_DE,
    wasteChecksheet: WASTECHECKSHEET_DE,
    purchaseOrderDetails: PURCHASEORDERDETAILS_DE,
  },
  es: {
    authentication: AUTHENTICATION_ES,
    common: COMMON_ES,
    createTransaction: CREATETRANSACTION_ES,
    drawer: DRAWER_ES,
    orderDetails: ORDER_DETAILS_ES,
    orderForm: ORDER_FORM_ES,
    orderHistory: ORDER_HISTORY_ES,
    productDetails: PRODUCT_DETAILS_ES,
    products: PRODUCTS_ES,
    quickOrder: QUICK_ORDER_ES,
    recipeUpdates: RECIPEUPDATES_ES,
    uomConversions: UOMCONVERSIONS_ES,
    wasteChecksheet: WASTECHECKSHEET_ES,
    purchaseOrderDetails: PURCHASEORDERDETAILS_ES,
  },
  fr: {
    authentication: AUTHENTICATION_FR,
    common: COMMON_FR,
    createTransaction: CREATETRANSACTION_FR,
    drawer: DRAWER_FR,
    orderDetails: ORDER_DETAILS_FR,
    orderForm: ORDER_FORM_FR,
    orderHistory: ORDER_HISTORY_FR,
    productDetails: PRODUCT_DETAILS_FR,
    products: PRODUCTS_FR,
    quickOrder: QUICK_ORDER_FR,
    recipeUpdates: RECIPEUPDATES_FR,
    uomConversions: UOMCONVERSIONS_FR,
    wasteChecksheet: WASTECHECKSHEET_FR,
    purchaseOrderDetails: PURCHASEORDERDETAILS_FR,
  },
  it: {
    authentication: AUTHENTICATION_IT,
    common: COMMON_IT,
    createCreditRequest : CREDITDREQUEST_IT, 
    createStockCount: CREATESTOCKCOUNT_IT,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_IT,
    createTransaction: CREATETRANSACTION_IT,
    drawer: DRAWER_IT,
    errorPage: ERROR_PAGE_IT,
    invoiceDetails: INVOICEDETAILS_IT,
    invoices: INVOICES_IT,
    manageNotifications: MANAGE_NOTIFICATIONS_IT,
    manageConfigurations: MANAGE_CONFIGURATIONS_IT,
    manageRoles: MANAGE_ROLES_IT,
    manageTransactions: MANAGETRANSACTIONS_IT,
    manageUsers: MANAGE_USERS_IT,
    orderDetails: ORDER_DETAILS_IT,
    orderForm: ORDER_FORM_IT,
    orderHistory: ORDER_HISTORY_IT,
    productDetails: PRODUCT_DETAILS_IT,
    products: PRODUCTS_IT,
    purchaseOrderDetails: PURCHASEORDERDETAILS_IT,
    quickOrder: QUICK_ORDER_IT,
    recipe: RECIPE_IT,
    recipeUpdates: RECIPEUPDATES_IT,
    stockCount: STOCKCOUNT_IT,
    stockCountReview: STOCK_COUNT_REVIEW_IT,
    stockCountScanning: STOCK_COUNT_SCANNING_IT,
    stockCountZones: STOCKCOUNTZONES_IT,
    stockTransfers: STOCK_TRANSFERS_IT,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_IT,
    uomConversions: UOMCONVERSIONS_IT,
    wasteChecksheet: WASTECHECKSHEET_IT,
    scheduledReports: SCHEDULED_REPORTS_IT,
  },
  ro: {
    authentication: AUTHENTICATION_RO,
    common: COMMON_RO,
    createTransaction: CREATETRANSACTION_RO,
    drawer: DRAWER_RO,
    purchaseOrderDetails: PURCHASEORDERDETAILS_RO,
    orderDetails: ORDER_DETAILS_RO,
    orderForm: ORDER_FORM_RO,
    orderHistory: ORDER_HISTORY_RO,
    productDetails: PRODUCT_DETAILS_RO,
    products: PRODUCTS_RO,
    quickOrder: QUICK_ORDER_RO,
    recipeUpdates: RECIPEUPDATES_RO,
    uomConversions: UOMCONVERSIONS_RO,
    wasteChecksheet: WASTECHECKSHEET_RO,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    resources: resources,
  });

export default i18n;