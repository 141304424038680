import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Button, Grid, TextField, MenuItem, TableContainer, Table,
  TableHead, TableBody, TableRow, TableCell, TableSortLabel,
  SvgIcon,
  Collapse,
  Typography,
  Switch,
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useNavigate } from 'react-router-dom';
import { Plus, SearchSm } from '../../../assets';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import { client, Order, OrderType, OrdersFilterOptions, DefaultOrderSettings } from '../../../app/services/api/orderManagementClient';
import CustomPagination from '../../../components/forms/Pagination';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import OrderStatusChip from '../../../components/shared/orders/OrderStatusChip';
import { applyRoundAndFormat } from '../../../utils';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';
import PageTitle from '../../../components/shared/PageTitle';
import PageArea from '../../../components/shared/PageArea';
import { useTranslation } from 'react-i18next'; 
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized'; 
import { isOrderStatus ,OrderStatusTranslationMap } from '../../../enums/OrderStatus';
import { OrderTypeTranslationMap, isOrderType } from '../../../enums/OrderType'; 
import MessagesSnackbar from './../../../components/shared/MessagesSnackbar';
 
const OrdersHistoryPage = () => {
  const navigate = useNavigate();
  const { selectedStore } = useContext(UserContext);
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const { t } = useTranslation('orderHistory');

  const [orderNumberSearch, setOrderNumberSearch] = useState('');
  const [productSearch, setProductSearch] = useState('');
  const [orderType, setOrderType] = useState(-1);
  const [status, setStatus] = useState(-1);
  const [deliveryDateFrom, setDeliveryDateFrom] = useState('');
  const [deliveryDateTo, setDeliveryDateTo] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('OrderNumber');
  const [totalPages, setTotalPages] = useState(0);
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false);
  const [showDefaultOrderSettings, setShowDefaultOrderSettings] = useState(false);
  const [defaultOrderSettings, setDefaultOrderSettings] = useState<DefaultOrderSettings>();

  const [orders, setOrders] = useState<Order[]>([]);
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  useEffect(() => {
    loadData();
  }, [page, selectedStore]);

  const loadData = async () => {
    setLoading(true);
    await fetchOrderTypes();
    await fetchOrders(getFilterOptions(false));
    await fetchDefaultOrderSettings();
    setLoading(false);
  };

  const fetchOrders = async (filters: OrdersFilterOptions) => {
    try {
      const ordersResponse = await client.getOrders(filters);
      setOrders(ordersResponse.data.items);
      setTotalPages(ordersResponse.data.totalPages);
    } catch (error) {
      console.error(t('errorLoading'), error);
    }
  };

  const fetchDefaultOrderSettings = async () => {
    try {
      const defaultSettings = await client.getDefaultOrderSettings(selectedStore?.storeNumber);
      setDefaultOrderSettings(defaultSettings.data);
    } catch (error) {
      console.error(t('errorLoading'), error);
    }
  };

  const fetchOrderTypes = async () => {
    try {
      const orderTypesResponse = await client.getOrderTypes(selectedStore?.storeNumber);
      setOrderTypes(orderTypesResponse.data);
    } catch (error) {
      console.error(t('errorLoading'), error);
    }
  };

  const handleSort = async (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setLoading(true);
    await fetchOrders({
      orderNumber: orderNumberSearch,
      item: productSearch,
      orderType: orderType,
      status: status,
      deliveryDateFrom: deliveryDateFrom,
      deliveryDateTo: deliveryDateTo,
      storeNumber: selectedStore?.storeNumber,
      pageNumber: page,
      pageSize: pageSize,
      sort: property,
      direction: isAsc ? 'desc' : 'asc',
    });
    setLoading(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    await fetchOrders(getFilterOptions(true));
    setLoading(false);
  };

  const handleClearFilters = async () => {
    setLoading(true);
    await fetchOrders({
      orderNumber: '',
      item: '',
      orderType: -1,
      status: -1,
      deliveryDateFrom: '',
      deliveryDateTo: '',
      storeNumber: selectedStore?.storeNumber,
      pageNumber: 1,
      pageSize: 10,
      sort: 'OrderNumber',
      direction: 'desc',
    });
    setPage(0);
    setPageSize(10);
    setOrderNumberSearch('');
    setProductSearch('');
    setOrderType(-1);
    setStatus(-1);
    setDeliveryDateFrom('');
    setDeliveryDateTo('');
    setLoading(false);
  };

  const getFilterOptions = (resetPage: boolean) => {
    const filterOptions: OrdersFilterOptions = {
      storeNumber: selectedStore?.storeNumber,
      orderNumber: orderNumberSearch,
      item: productSearch,
      orderType: orderType,
      status: status,
      deliveryDateFrom: deliveryDateFrom,
      deliveryDateTo: deliveryDateTo,
      pageNumber: resetPage ? 0 : page,
      pageSize: pageSize,
      sort: orderBy,
      direction: order,
    };
    return filterOptions;
  };

  const updateDefaultSettings = async () => {
    if (!defaultOrderSettings) {
      return;
    }
    setLoading(true);
    client.updateDefaultOrderSettings(selectedStore?.storeNumber, defaultOrderSettings)
      .then(() => {
        setIsShowSuccessAlert(true);
      }).catch((error) => {
        console.error(t('errorUpdating'), error);
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleDefaultOrderSettingsChange = (event: ChangeEvent<HTMLInputElement>, propertyName: keyof DefaultOrderSettings) => {
    setDefaultOrderSettings((prev) => {
      if (!prev) return prev;

      return {
        ...prev,
        [propertyName]: event.target.checked,
      };
    });
  };

  const handleDsdSupplierChange = (event: ChangeEvent<HTMLInputElement>, supplierNumber: string) => {
    setDefaultOrderSettings((prev) => {
      if (!prev) return prev;

      const dsdSuppliers = prev?.dsdSuppliers.map(supplier =>
        supplier.number === supplierNumber ? { ...supplier, enabled: event.target.checked } : supplier,
      );
      return {
        ...prev,
        dsdSuppliers: dsdSuppliers,
      };
    });
  };
  
  return (
    <PageArea>
      <Grid container>
        <Grid item
          xs={12}
          sx={{ textAlign: 'left' }}>
          <PageTitle>
            {t('orders')}
          </PageTitle>
        </Grid>
        <Grid item
          xs={12}
          container
          rowGap={5}
          columnGap={5}
          justifyContent="flex-end">
          {
            defaultOrderSettings?.isStarbucksStore &&
            <Button variant="secondary"
              size="lg"
              onClick={() => setShowDefaultOrderSettings(!showDefaultOrderSettings)}>
              {showDefaultOrderSettings ? t('hideDefaultOrderSettings') : t('showDefaultOrderSettings')}
            </Button>
          }
          <Button variant="primary"
            size="lg"
            startIcon={<SvgIcon> <Plus fill='white' /> </SvgIcon>}
            sx={{ ml: { sm: 2 } }}
            onClick={() => navigate('/store/quick-order')}>
            {t('newOrder')}
          </Button>
        </Grid>
        <Collapse in={showDefaultOrderSettings}
          timeout="auto"
          sx={{ display: 'flex', width: '100%' }}
          unmountOnExit>
          <Grid item
            container
            mt={5}
            xs={12}
            flexDirection={'column'}
            sx={{
              width: '100%',
              padding: '20px 20px 20px 20px',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              border: `1px solid ${theme.palette.custom.gray[200]}`,
            }}>
            <Grid item
              display={'flex'}
              xs={12}
              justifyContent={'space-between'}>
              <Typography
                sx={{ mb: 5 }}
                fontWeight={600}
                variant='textXL'>{t('defaultOrderSettings')}</Typography>
              <Button variant="primary"
                size="lg"
                onClick={() => updateDefaultSettings()}>
                {t('update')}
              </Button>
            </Grid>
            <Typography
              variant='textLG'
              fontWeight={600}>
              {t('copyOrderDetails')}
            </Typography>
            <Typography
              variant='textSM'
              fontStyle={'italic'}
              sx={{ mb: 8 }}>
              {t('copyOrderInfo')}
            </Typography>
            {
              orderTypes.find(x => x.name === 'Fresh & Dairy') && 
              <Grid item
                display={'flex'}
                justifyContent={'space-between'}
                xs={6}>
                <Typography variant='textMD'>{t('dailyMorningFreshAndDairyOrder')}</Typography>
                <Switch checked={defaultOrderSettings?.isFreshAndDairyEnabled}
                  onChange={(event) => handleDefaultOrderSettingsChange(event, 'isFreshAndDairyEnabled')}></Switch>
              </Grid>
            }
            {
              orderTypes.find(x => x.name === 'Direct Store Delivery') && 
              defaultOrderSettings?.dsdSuppliers.map(supplier => 
                <Grid item
                  key={supplier.id}
                  display={'flex'}
                  justifyContent={'space-between'}
                  xs={6}>
                  <Typography variant='textMD'>{t('directStoreDelivery')} - {supplier.name} {t('order')}</Typography>
                  <Switch checked={supplier.enabled}
                    onChange={(event) => handleDsdSupplierChange(event, supplier.number)}></Switch>
                </Grid>)
            }
            {
              orderTypes.find(x => x.name === 'Ambient') && 
              <Grid item
                display={'flex'}
                justifyContent={'space-between'}
                xs={6}>
                <Typography variant='textMD'>{t('weeklyAfternoonStandardAmbientOrder')}</Typography>
                <Switch checked={defaultOrderSettings?.isAmbientEnabled}
                  onChange={(event) => handleDefaultOrderSettingsChange(event, 'isAmbientEnabled')}></Switch>
              </Grid>
            }
            <Grid item
              display={'flex'}
              justifyContent={'space-between'}
              xs={6}>
              <Grid xs={12}
                display={'flex'}
                flexDirection={'column'}>
                <Typography variant='textMD'>{t('sendWarningEmail')}</Typography>
                <Typography variant='textSM'
                  fontStyle={'italic'}>{t('warningEmailInfo')}</Typography>
              </Grid>
              <Switch checked={defaultOrderSettings?.isWarningEmailEnabled}
                onChange={(event) => handleDefaultOrderSettingsChange(event, 'isWarningEmailEnabled')}></Switch>
            </Grid>
          </Grid>
        </Collapse>
        <Grid item
          container
          mt={5}
          xs={12}
          sx={{
            padding: '20px 20px 20px 20px',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            border: `1px solid ${theme.palette.custom.gray[200]}`,
          }}>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <TextField
              fullWidth
              size='small'
              label={t('searchbyOrderNumber')}
              value={orderNumberSearch}
              onChange={(e) => setOrderNumberSearch(e.target.value)}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <TextField
              fullWidth
              size='small'
              label={t('searchByProduct')}
              value={productSearch}
              onChange={(e) => setProductSearch(e.target.value)}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <TextField
              fullWidth
              size='small'
              label={t('orderType')}
              value={orderType}
              required
              onChange={(e) => setOrderType(Number(e.target.value))}
              select
            >
              <MenuItem value="-1">{t('all')}</MenuItem>
              {orderTypes.map((type) => (
                <MenuItem key={type.id}
                  value={type.id}>
                  {t(OrderTypeTranslationMap[type.name as keyof typeof OrderTypeTranslationMap]) || type.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <TextField
              fullWidth
              size='small'
              label={t('status')}
              value={status}
              required
              onChange={(e) => setStatus(Number(e.target.value))}
              select
            >
              <MenuItem value="-1">{t('all')}</MenuItem>
              <MenuItem value="0">{t('editable')}</MenuItem>
              <MenuItem value="1">{t('orderRequested')}</MenuItem>
              <MenuItem value="5">{t('orderConfirmed')}</MenuItem>
              <MenuItem value="6">{t('orderShipped')}</MenuItem>
              <MenuItem value="12">{t('orderNotPlaced')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <DatePickerLocalized
              useCalendar={false}
              label={t('deliveryDateFrom')}
              value={deliveryDateFrom}
              translations={{
                clearButtonLabel: t('calendar.clearButtonLabel'),
                todayButtonLabel: t('calendar.todayButtonLabel'),
                cancelButtonLabel: t('calendar.cancelButtonLabel'),
                okButtonLabel: t('calendar.okButtonLabel'),
              }}
              onChange={(newValue) => {
                if (newValue === '') {
                  setDeliveryDateFrom('');
                } else if (newValue) {
                  setDeliveryDateFrom(newValue);
                  if (deliveryDateTo && newValue > deliveryDateTo) {
                    setDeliveryDateTo('');
                  }
                }
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <DatePickerLocalized
              useCalendar={false}
              label={t('deliveryDateTo')}
              translations={{
                clearButtonLabel: t('calendar.clearButtonLabel'),
                todayButtonLabel: t('calendar.todayButtonLabel'),
                cancelButtonLabel: t('calendar.cancelButtonLabel'),
                okButtonLabel: t('calendar.okButtonLabel'),
              }}
              value={deliveryDateTo}
              onChange={(newValue) => {
                if (newValue) {
                  setDeliveryDateTo(newValue);
                }
              }}
              shouldDisableDate={(date) => {
                return deliveryDateFrom ? date < deliveryDateFrom : false;
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <Button fullWidth
              variant="secondary"
              size="lg"
              onClick={handleClearFilters}>
              {t('clearFilters')}
            </Button>
          </Grid>
          <Grid item
            xs={12}
            sm={6}
            md={3}
            p={5}>
            <Button fullWidth
              variant="primary"
              size="lg"
              startIcon={<SearchSm />}
              onClick={handleSearch}>
              {t('search')}
            </Button>
          </Grid>
          <Grid item
            xs={12}
            p={5}>
            {loading ? <LoadingWheel /> :
              <TableContainer>
                <Table
                  size="small"
                  aria-label="a table"
                >
                  <TableHead
                    sx={{
                      paddingTop: '10px',
                      backgroundColor: theme.palette.custom.gray[200],
                    }}>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'OrderNumber'}
                          direction={order}
                          onClick={() => handleSort('OrderNumber')}>
                          {t('orderNumber')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="center">
                        <TableSortLabel
                          active={orderBy === 'Status'}
                          direction={order}
                          onClick={() => handleSort('Status')}>
                          {t('status')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'OrderType'}
                          direction={order}
                          onClick={() => handleSort('OrderType')}>
                          {t('orderType')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'DeliveryDate'}
                          direction={order}
                          onClick={() => handleSort('DeliveryDate')}>
                          {t('deliveryDate')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'CutOffDate'}
                          direction={order}
                          onClick={() => handleSort('CutOffDate')}>
                          {t('cutOffDate')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'TotalCost'}
                          direction={order}
                          onClick={() => handleSort('TotalCost')}>
                          {t('totalCost')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        align="left">
                        <TableSortLabel
                          active={orderBy === 'TotalQuantity'}
                          direction={order}
                          onClick={() => handleSort('TotalQuantity')}>
                          {t('totalQuantity')}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow
                        key={order.id}
                        hover
                        onClick={() => navigate(`/store/order/${order.id}`)}
                      >
                        <TableCell>
                          <Grid container
                            alignItems="center">
                            <Grid item>
                              {order.id}
                            </Grid>
                            <Grid item>
                              {order.emergencyOrder &&
                                <LocalShippingIcon color="error"
                                  fontSize="small"
                                  sx={{ ml: 5 }} />}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <OrderStatusChip orderStatus={isOrderStatus(order.status) ? t(OrderStatusTranslationMap[order.status]) : order.status} />
                        </TableCell>
                        <TableCell>
                          {isOrderType(order.orderType) ? t(OrderTypeTranslationMap[order.orderType]) : order.orderType}
                          {order.emergencyOrder ? ' (EO)' : ''}
                        </TableCell>
                        <TableCell>{order.deliveryDate}</TableCell>
                        <TableCell>{order.cutOffDate}</TableCell>
                        <TableCell>{applyRoundAndFormat(order.totalCost, currencyCode)}</TableCell>
                        <TableCell>{order.totalQuantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <CustomPagination
                  page={page}
                  setPage={setPage}
                  maxPages={totalPages}
                  breakpointForChangeDisplay={120000}
                />
              </TableContainer>
            }
          </Grid>
        </Grid>
      </Grid>
      <MessagesSnackbar
        open={isShowSuccessAlert}
        onClose={() => setIsShowSuccessAlert(false)}
        message={t('successfullyUpdated')}
        severity="success"
        duration={3000}
      />
    </PageArea>
  );
};

export default OrdersHistoryPage;
