import { useContext, useEffect, useState } from 'react';
import {
  Button, Grid, TextField, Checkbox, TableContainer, Table,
  TableHead, TableBody, TableRow, TableCell, Typography, TableSortLabel,
  SvgIcon, InputAdornment, Switch, Autocomplete, Divider, Snackbar, Alert, useMediaQuery, Box, Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateField } from '@mui/x-date-pickers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { Plus, SearchSm } from './../../../assets';
import { OrderKind } from '../../../enums/OrderKind';
import { OrderType as OrderTypeEnum, OrderTypeTranslationMap } from '../../../enums/OrderType';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import { client, SimpleCategory, DeliverySchedule, OrderDetails, OrderItemDetails, DeleteOrderItemsRequest } from '../../../app/services/api/orderManagementClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import OrderStatusChip from '../../../components/shared/orders/OrderStatusChip';
import CopyOrderModal from '../../../components/shared/orders/CopyOrderModal';
import { ConfirmationDialog } from '../../../components/shared/dialogs/ConfirmationDialog';
import { OrderStatus } from '../../../enums/OrderStatus';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';
import { applyRoundAndFormat } from '../../../utils';
import PageTitle from '../../../components/shared/PageTitle';
import PageArea from '../../../components/shared/PageArea';
import BackButton from '../../../components/shared/actions/BackButton';
import { useTranslation } from 'react-i18next';
import DatePickerLocalized from '../../../components/shared/DatePickerLocalized';
import TextWithOverflowTooltip from '../../../components/shared/TextWithOverflowTooltip';
import { starbucksFranchises } from '../../../enums/FranchiseNames';
import { isOrderCategory, OrderCategoriesTranslationMap } from '../../../enums/OrderCategories';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';

interface DialogState {
  open: boolean;
  title: string;
  message: string;
  confirmAction: () => void;
}

const OrderDetailsPage = () => {
  dayjs.extend(duration);
  const navigate = useNavigate();
  const { selectedStore } = useContext(UserContext);
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();
  const { t } = useTranslation('orderDetails');
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [loading, setLoading] = useState(true);
  const [orderType, setOrderType] = useState<string>('');
  const [deliveryDate, setDeliveryDate] = useState<Dayjs | null>(null);
  const [cutOffDate, setCutOffDate] = useState<Dayjs | null>(null);
  const [orderKind, setOrderKind] = useState(OrderKind.Emergency);

  const [productSearch, setProductSearch] = useState('');
  const [category, setCategory] = useState<number>(-1);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('Name');

  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>();
  const [filteredItems, setFilteredItems] = useState<OrderItemDetails[]>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [categories, setCategories] = useState<SimpleCategory[]>([]);
  const [deliverySchedules, setDeliverySchedules] = useState<DeliverySchedule[]>([]);

  const [itemQuantities, setItemQuantities] = useState<{ [key: number]: number }>({});
  const [isCopyOrderModalOpen, setIsCopyOrderModalOpen] = useState(false);

  const [modifiedItems, setModifiedItems] = useState<Set<number>>(new Set());
  const [lastFocusedItemId, setLastFocusedItemId] = useState<number | null>(null);
  const [showAutosaveMessage, setShowAutosaveMessage] = useState<boolean>(false);
  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);

  const INACTIVITY_TIMEOUT = 60000; // 1 min on milliseconds

  const [navigateToIndex, setNavigateToIndex] = useState<boolean>(false);
  const [isExitAndSaveClicked, setIsExitAndSaveClicked] = useState<boolean>(false);


  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    title: '',
    message: '',
    confirmAction: () => { },
  });

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const { id } = useParams();

  const isStarBucksRetail = starbucksFranchises.includes(selectedStore?.franchiseName ?? '');
  const showMessageWhenNoCost = isStarBucksRetail && orderDetails?.status != 'Order Confirmed';
 
  const blocker = useBlocker(() => modifiedItems.size > 0 && !isExitAndSaveClicked);

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedStore, id]);

  useEffect(() => {
    if (orderDetails) {
      const categoriesById: Record<number, SimpleCategory> = {};
      orderDetails.items.forEach((item) => {
        if (!categoriesById[item.categoryId]) {
          categoriesById[item.categoryId] = { id: item.categoryId, name: item.category };
        }
      });
      setCategories(Object.values(categoriesById));
    }
  }, [orderDetails]);

  useEffect(() => {
    if (modifiedItems.size > 0 ) {
      handleAutosaveMessage(false);
      startInactivityTimer();
    }
  }, [itemQuantities]);


  useEffect(() => {
    const beforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if (modifiedItems.size > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [modifiedItems]);

  useEffect(() => {
    if (navigateToIndex) {
      navigate('/store/order');
      if (blocker?.state === 'blocked') {
        blocker.proceed();
      }
      setNavigateToIndex(false);
    }
  }, [navigateToIndex, blocker]);
  
  const handleLeavePageModalCancel = () => {
    if (blocker?.proceed) {
      blocker.proceed(); 
    } };

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset(); 
    } };

  const handleLeavePageModalConfirm = () => {
    handleSaveChanges();
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  const startInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
    
    const timer = setTimeout(async () => {
      if (modifiedItems.size > 0) {
        modifiedItems.forEach(itemId => {
          autosaveItem(itemId);
        });
      }    
    }, INACTIVITY_TIMEOUT);
    
    setInactivityTimer(timer);
  };

  const handleAutosaveMessage = (changesSaved : boolean) => {
    setShowAutosaveMessage(changesSaved);
  };
  
  const handleItemFocus = (itemId: number) => {
    if (lastFocusedItemId !== null && lastFocusedItemId !== itemId && modifiedItems.has(lastFocusedItemId)) {

      autosaveItem(lastFocusedItemId);
    }
    setLastFocusedItemId(itemId);
  };

  const autosaveItem = async (itemId: number) => {
    if (!orderDetails) return;
 
    const updatedItems = orderDetails.items
      .map(item => ({
        id: item.itemId,
        quantity: itemQuantities[item.itemId] ?? item.quantity,
      }));

    const updatedOrder = {
      id: orderDetails.id,
      items: updatedItems,
    };

    try {
      const result = await client.updateOrder(selectedStore?.storeNumber, updatedOrder);
      if (result.success) {
        handleAutosaveMessage(true);
        setModifiedItems(prev => {
          const newModifiedItems = new Set(prev);
          newModifiedItems.delete(itemId);
          return newModifiedItems;
        });
      } 
    } catch (error) {
      handleSnackbarOpen(t('updateError') + ' ' + error, 'error');
    } 
  };


  const loadData = async () => {
    setLoading(true);
    await fetchOrderDetails();
    setLoading(false);
  };

  const fetchDeliverySchedules = async (selectedOrderType: number | undefined, supplierNumber: string) => {
    try {
      const deliverySchedulesResponse = await client.getDeliverySchedules(selectedStore?.storeNumber, selectedOrderType, supplierNumber);
      setDeliverySchedules(deliverySchedulesResponse.data || []);
    } catch (error) {
      handleSnackbarOpen(t('loadingErrorMessage') + ' ' + error, 'error');
    }
  };
  const [orderConfirmed, setOrderConfirmed] = useState<boolean>();
  const fetchOrderDetails = async () => {
    try {
      const result = await client.getOrder(selectedStore?.storeNumber, parseInt(id ?? ''));
      setOrderDetails(result.data);
      setFilteredItems(result.data.items);
      if (result.data.status == 'Order Confirmed') {
        setOrderConfirmed(true);
      } else { setOrderConfirmed(false); }
      setOrderType(result.data.orderType);
      setDeliveryDate(dayjs(result.data.deliveryDate, 'DD-MM-YYYY'));
      setCutOffDate(dayjs(result.data.cutOffDate, 'DD-MM-YYYY'));
      setOrderKind(result.data.emergencyOrder ? OrderKind.Emergency : OrderKind.Forecast);
      await fetchDeliverySchedules(result.data.orderTypeId, result.data.supplierNumber);
    } catch (error) {
      handleSnackbarOpen(t('fetchingErrorMessage') + ' ' + error, 'error');
    }
  };

  const disableUnavailableDates = (date: Dayjs) => {
    const today = dayjs();
    const dayjsDate = dayjs(date);
    if (orderKind === OrderKind.Emergency) {
      return dayjsDate.isBefore(today, 'day');
    }
    return !deliverySchedules.some((schedule) =>
      dayjsDate.isSame(dayjs(schedule.deliveryDate, 'DD-MM-YYYY'), 'day'),
    );
  };

  const handleDeliveryDateChange = async (newDate: Dayjs | null) => {
    if (!newDate || orderKind === OrderKind.Emergency) {
      handleSnackbarOpen(t('deliveryChangeWarning'), 'warning');
      return;
    }
    const formattedDate = dayjs(newDate).format('DD-MM-YYYY');
    try {
      const orderResponse = await client.getOrderByDeliveryDate(selectedStore?.storeNumber, orderDetails?.orderTypeId ?? -1, formattedDate, orderDetails?.supplierNumber ?? '');
      if (orderResponse?.data) {
        navigate(`/store/order/${orderResponse.data.id}`);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchingErrorMessage') + ' ' + error, 'error');
    }
  };

  const handleSearch = () => {
    if (orderDetails) {
      const filtered = orderDetails.items.filter(item => {
        const matchesSearchText = productSearch === '' || item.sku.toLowerCase().includes(productSearch.toLowerCase()) || item.name.toLowerCase().includes(productSearch.toLowerCase());
        const matchesCategory = category === -1 || item.categoryId === category;
        return matchesSearchText && matchesCategory;
      });
      setFilteredItems(filtered);
    }
  };

  const handleDeleteOrderItem = async (orderId: number, orderItemId: number) => {
    if (!orderId || !orderItemId) return;
    setLoading(true);
    try {
      const result = await client.deleteOrderItem(selectedStore?.storeNumber, orderId.toString(), orderItemId.toString());
      if (result.success) {
        handleSnackbarOpen(t('deleteSuccess'), 'success');
        await fetchOrderDetails();
      } else {
        handleSnackbarOpen(t('deleteItemFail'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('deleteItemFail') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (itemId: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedItemIds((prev) => [...prev, itemId]);
    } else {
      setSelectedItemIds((prev) => prev.filter((id) => id !== itemId));
    }
  };

  const handleDeleteSelectedItems = () => {
    if (!orderDetails || selectedItemIds.length === 0) return;
    const selectedSKUs = orderDetails.items
      .filter(item => selectedItemIds.includes(item.id))
      .map(item => item.sku)
      .join(', ');
    setDialogState({
      open: true,
      title: t('deleteModalTitle'),
      message: `${t('deleteMultiModalMessage')}: ${selectedSKUs}?`,
      confirmAction: () => handleConfirmMultipleDeletion(),
    });
  };

  const handleConfirmMultipleDeletion = async () => {
    if (!orderDetails) return;
    setLoading(true);
    try {
      const request: DeleteOrderItemsRequest = {
        orderId: orderDetails.id,
        items: selectedItemIds,
      };
      const result = await client.deleteOrderItems(selectedStore?.storeNumber, request);
      if (result.success) {
        handleSnackbarOpen(t('multipleDeleteSuccess'), 'success');
        setSelectedItemIds([]);
        await fetchOrderDetails();
      } else {
        handleSnackbarOpen(t('multipleDeleteError'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('multipleDeleteError') + ' ' + error, 'error');
    } finally {
      setLoading(false);
      setDialogState({ ...dialogState, open: false });
    }
  };

  const handleConfirmDeletion = async (itemId: number) => {
    if (itemId !== null) {
      await handleDeleteOrderItem(Number(id), itemId);
      setDialogState({ ...dialogState, open: false });
    }
  };

  const handleOpenDialogForDeletion = (itemId: number) => {
    setDialogState({
      open: true,
      title: t('deleteModalTitle'),
      message: t('deleteModalMessage'),
      confirmAction: () => handleConfirmDeletion(itemId),
    });
  };

  const handleConfirmAction = async () => {
    if (dialogState.confirmAction) {
      await dialogState.confirmAction();
    }
    setDialogState({ ...dialogState, open: false });
  };

  const handleCancelDialog = () => {
    setDialogState({ ...dialogState, open: false });
  };

  const clearFilters = () => {
    setProductSearch('');
    setCategory(-1);
    if (orderDetails) {
      setFilteredItems(orderDetails.items);
    }
  };

  const handleSort = async (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSubmitOrder = async () => {
    if (!selectedStore || !orderDetails) {
      handleSnackbarOpen(t('submitError'), 'error');
      return;
    }
    setLoading(true);
    const result = await client.submitOrder(selectedStore.storeNumber, orderDetails.id.toString(), orderDetails);
    if (result.success) {
      handleSnackbarOpen(t('submitSuccess'), 'success');
      await fetchOrderDetails();
    } else {
      handleSnackbarOpen(t('submitFail'), 'error');
    }
    setLoading(false);
  };

  const handleQuantityChange = (itemId: number, newQuantity: number) => {
    if (itemId && !modifiedItems.has(itemId)) {
      setModifiedItems(prev => new Set(prev.add(itemId)));
    }
    setItemQuantities(prevQuantities => ({
      ...prevQuantities,
      [itemId]: newQuantity,
    }));
   
  };
 
  const handleSaveChanges = async () => {
  
    if (!orderDetails) return;

    setLoading(true);
    const updatedItems = orderDetails.items.map(item => ({
      id: item.itemId,
      quantity: itemQuantities[item.itemId] ?? item.quantity,
    }));

    const updatedOrder = {
      id: orderDetails.id,
      items: updatedItems,
    };

    try {
      const result = await client.updateOrder(selectedStore?.storeNumber, updatedOrder);
      if (result.success) {
        setModifiedItems(new Set());
        handleSnackbarOpen(t('updateSuccess'), 'success');
        await fetchOrderDetails();

      } else {
        handleSnackbarOpen(t('updateError'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('updateError') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }

  };

  const handleOrderCopyConfirm = async (orderId: number) => {
    setIsCopyOrderModalOpen(false);
    setLoading(true);
    try {
      const result = await client.copyOrder(selectedStore?.storeNumber, orderDetails?.id.toString(), orderId.toString());
      if (result.success) {
        handleSnackbarOpen(t('copySuccess'), 'success');
        await fetchOrderDetails();
      } else {
        handleSnackbarOpen(t('copyError'), 'error');
      }
    } catch (error) {
      handleSnackbarOpen(t('copyError') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOrder = async () => {
    if (!selectedStore || !orderDetails) {
      handleSnackbarOpen(t('deleteError'), 'error');
      return;
    }
    setLoading(true);
    const result = await client.deleteOrder(selectedStore.storeNumber, orderDetails.id.toString());
    if (result.success) {
      navigate('/store/orders-history');
    } else {
      handleSnackbarOpen(t('deleteFail'), 'error');
    }
    setLoading(false);
  };

  const handleExitAndSave = async () => {
    setIsExitAndSaveClicked(true);
    if (!loading) {
      if (orderDetails?.status === OrderStatus.Editable) {
        await handleSaveChanges();
      }
      navigate('/store/orders-history');
    }
  };

  const handleSnackbarOpen = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event: unknown) => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getTotalFormattedPrice = (item: OrderItemDetails, orderConfirmed: boolean, currencyCode: string | undefined) => {
    const totalPrice = orderConfirmed ? (item.price * item.confirmedQuantity) : (item.price * item.quantity);
    return applyRoundAndFormat(totalPrice, currencyCode);
  };

  const autocompleteOptions = [{ id: -1, name: 'All', parentName: '' }, ...(Array.isArray(categories) ? categories : [])];

  const canSubmitOrder = !loading && orderKind === OrderKind.Emergency && orderDetails?.status === OrderStatus.Editable && orderDetails.items.length > 0;

  return (
    <PageArea>
      <Grid item
        xs={12}
        mb={10}
        sx={{ textAlign: 'left' }}>
        <BackButton
          handleClick={handleExitAndSave}
          title={t('exitAndSave')}
          isLink={false}
        />
      </Grid>
      <Grid container>
        <Grid item
          container
          xs={12}>
          <Grid item
            container
            spacing={5}
            alignItems={'end'} >
            <Grid item
              xs={12}
              md={6}>
              <PageTitle>{t('order')} {id}</PageTitle>
              <Grid container
                alignItems={'center'}>
                <Grid item>
                  <Typography mr={2}
                    sx={{ fontSize: '18px' }}>
                    {t('status')}
                  </Typography>
                </Grid>
                <Grid item>
                  <OrderStatusChip orderStatus={orderDetails?.status ?? ''}
                    size='medium' />
                </Grid>
              </Grid>
              {orderKind === OrderKind.Forecast && (
                <Typography mt={5}
                  sx={{ fontSize: '18px' }}>{t('timeToOrderCutoff')} <strong>{orderDetails?.cutOffTimeLeft}</strong></Typography>
              )}
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                variant="bad"
                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                size="lg"
                fullWidth
                onClick={handleDeleteOrder}
                startIcon={<DeleteIcon />}>
                {t('deleteOrder')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                variant="primary"
                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                size="lg"
                fullWidth
                startIcon={<SaveIcon />}
                onClick={handleSaveChanges}
              >
                {t('save')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                variant="primary"
                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                onClick={() => navigate(`/store/quick-order/${id}`)}
                size="lg"
                fullWidth
                startIcon={<Plus />}>
                {t('addItems')}
              </Button>
            </Grid>
            {showAutosaveMessage && <Grid item
              xs={12}
              sx={{ textAlign: 'right',  mr:'8px' }}>
              <Typography variant='textLG'
              >
                {t('changesSaved') } 
              </Typography>
            </Grid>}
          </Grid>
        </Grid>
        <Grid item
          container
          xs={12}
          mt={5}
          sx={{
            padding: '20px 20px 20px 20px',
            border: '1px solid',
            borderColor: theme.palette.custom.gray[300],
          }}>
          <Grid container
            spacing={2}>
            <Grid item
              xl={3}
              md={isMobile ? 12 : 7}
              xs={isMobile ? 12 : 6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 0 }} >
              <Box>
                <DatePickerLocalized
                  label=''
                  useCalendar={true}
                  valueForDateCalendar={deliveryDate}
                  onChangeDateCalendar={handleDeliveryDateChange}
                  shouldDisableDateCalendar={disableUnavailableDates}
                  disabled={loading || orderKind === OrderKind.Emergency}
                  disableHighlightToday={true}
                />
              </Box>

            </Grid>
            <Grid item
              xl={9}
              md={isMobile ? 12 : 5}
              xs={isMobile ? 12 : 6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: 0 }}
            >
              <Grid container
                spacing={2}>
                <Grid item
                  xl={9}
                  md={12}
                  xs={12}>
                  <Box>
                    <Grid container
                      spacing={2}>
                      <Grid item
                        xl={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && !isMobile ? 4 : 6}
                        md={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && !isMobile ? 6 : 12}
                        xs={12}
                        p={3}>
                        <TextField
                          fullWidth
                          size="small"
                          label={t('orderType')}
                          disabled
                          value={t(OrderTypeTranslationMap[orderType as keyof typeof OrderTypeTranslationMap]) || orderType}
                        />
                      </Grid>
                      {orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery && (
                        <Grid item
                          xl={4}
                          md={!isMobile ? 6 : 12}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            label={t('supplier')}
                            disabled
                            value={orderDetails?.supplierName}
                          />
                        </Grid>
                      )}
                      <Grid item
                        xl={orderDetails?.orderTypeId === OrderTypeEnum.DirectStoreDelivery ? 4 : 6}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography component="div">
                          <Grid component="label"
                            container
                            alignItems="center"
                            spacing={1}>
                            <Grid item>{t('emergencyOrder')}</Grid>
                            <Grid item>
                              <Switch
                                checked={orderKind === OrderKind.Emergency}
                                name="orderKindSwitch"
                                readOnly
                                disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                              />
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <DateField
                          label={t('deliveryDate')}
                          disablePast={true}
                          disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                          readOnly
                          value={deliveryDate}
                        />
                      </Grid>
                      <Grid item
                        xl={6}
                        md={6}
                        xs={6}
                      >
                        <DateField
                          label={t('cutOffDate')}
                          disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                          readOnly
                          value={cutOffDate}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item
                  xl={3}
                  md={12}
                  xs={12}>
                  <Box>
                    <Grid container
                      spacing={2}>
                      <Grid item
                        xs={6}
                        p={3}>
                        <Button
                          variant="primary"
                          disabled={loading}
                          size="lg"
                          fullWidth
                          startIcon={<SearchSm />}
                        >
                          {t('viewAudit')}
                        </Button>
                      </Grid>
                      <Grid item
                        xs={6}>
                        <Button
                          variant="primary"
                          disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                          size="lg"
                          fullWidth
                          startIcon={<ContentCopyIcon />}
                          onClick={() => setIsCopyOrderModalOpen(true)}
                        >
                          {t('copyOrder')}
                        </Button>
                      </Grid>
                      <Grid item
                        xs={12}>
                        <Button
                          variant="primary"
                          disabled={!canSubmitOrder}
                          onClick={handleSubmitOrder}
                          size="lg"
                          fullWidth
                        >
                          {t('submitOrder')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item
            xs={12}
            mt={10}
            mb={10}>
            <Divider />
          </Grid>
          <Grid item
            container
            xs={12}
            spacing={5}>
            <Grid item
              xs={12}
              md={3}
              p={5}>
              <TextField
                fullWidth
                size='small'
                placeholder={t('searchByItemNameOrSKU')}
                value={productSearch}
                onChange={(e) => setProductSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon><SearchSm /></SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item
              xs={12}
              md={3}
              p={5}>
              <Autocomplete
                size='small'
                options={autocompleteOptions}
                getOptionLabel={(option) => isOrderCategory(option.name) ? t(OrderCategoriesTranslationMap[option.name as keyof typeof OrderCategoriesTranslationMap]) : option.name}
                value={categories.find(cat => cat.id === category) ?? autocompleteOptions[0]}
                onChange={(event, newValue) => {
                  setCategory(newValue ? newValue.id : -1);
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('category')}
                    placeholder={t('choose')} />
                )}
              />
            </Grid>
            <Grid item
              xs={12}
              md={2}
              p={5}>
              <Button
                variant="bad"
                disabled={loading || selectedItemIds.length === 0 || orderDetails?.status !== OrderStatus.Editable}
                size="lg"
                fullWidth
                onClick={handleDeleteSelectedItems}
                startIcon={<DeleteIcon />}
              >
                {t('deleteSelected')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}
              p={5}>
              <Button fullWidth
                variant="secondary"
                disabled={loading}
                onClick={clearFilters}
                size="lg">
                {t('clearFilters')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}
              p={5}>
              <Button fullWidth
                variant="primary"
                disabled={loading}
                onClick={handleSearch}
                size="lg"
                startIcon={<SearchSm />}>
                {t('search')}
              </Button>
            </Grid>
          </Grid>
          <Grid item
            xs={12}
            p={5}>
            {loading ? (
              <LoadingWheel />
            ) : (
              <TableContainer
                component={'div'}
                sx={{
                  overflowX: 'auto',
                  maxWidth: '100%',
                }}
              >
                <Table
                  size="small"
                  aria-label="a table"
                  sx={{
                    minWidth: '700px',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '60%' }}>
                        {t('itemDetails')}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        <TableSortLabel
                          active={orderBy === 'Ordered'}
                          direction={order}
                          onClick={() => handleSort('Ordered')}
                          sx={{
                            transform: 'translate(15%)',
                          }}
                        >
                          {t('ordered')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '9%' }}>
                        <TableSortLabel
                          active={orderBy === 'Confirmed'}
                          direction={order}
                          onClick={() => handleSort('Confirmed')}
                          sx={{
                            transform: 'translate(15%)',
                          }}
                        >
                          {t('confirmed')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center"
                        sx={{ verticalAlign: 'middle', fontWeight: 'bold', width: '9%' }}>
                        <TableSortLabel
                          active={orderBy === 'Shipped'}
                          direction={order}
                          onClick={() => handleSort('Shipped')}
                          sx={{
                            transform: 'translate(20%)',
                          }}
                        >
                          {t('shipped')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '9%' }}>
                        <TableSortLabel
                          active={orderBy === 'Cost'}
                          direction={order}
                          onClick={() => handleSort('Cost')}
                          sx={{
                            transform: 'translate(10%)',
                          }}
                        >
                          {t('cost')}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', width: '9%' }}>
                        {t('actions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredItems.map((item) => (
                      <TableRow 
                        key={item.id}
                        hover
                        onFocus={() => handleItemFocus(item.itemId)}
                      >
                        <TableCell sx={{ width: '60%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          <Grid container
                            alignItems={'center'}
                            spacing={1}
                            wrap="nowrap">
                            <Grid item
                              xs="auto">
                              <Checkbox
                                checked={selectedItemIds.includes(item.id)}
                                onChange={(event) =>
                                  handleCheckboxChange(item.id, event.target.checked)
                                }
                                disabled={
                                  loading || orderDetails?.status !== OrderStatus.Editable
                                }
                              />
                            </Grid>
                            <Grid item
                              xs="auto">
                              <img
                                style={{
                                  objectFit: 'cover',
                                  width: '150px',
                                  height: isLargeScreen ? '75px' : '150px',
                                  borderRadius: '8px',
                                  border: '1px solid #ddd',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                  display: 'block',
                                  margin: '0',
                                  padding: '0',
                                }}
                                alt="item-thumbnail"
                                src={
                                  item.thumbnailUrl
                                    ? item.thumbnailUrl
                                    : 'https://placehold.co/100x150'
                                }
                                loading="lazy"
                              />
                            </Grid>
                            <Grid item
                              sx={{ minWidth: 0, paddingLeft: '10px !important', flex: 1 }}>
                              {isLargeScreen ? (
                                <Grid container
                                  spacing={2}>
                                  <Grid item
                                    xs={6}>
                                    <TextWithOverflowTooltip text={item.name} />
                                    <TextWithOverflowTooltip text={item.sku} />

                                    <TextWithOverflowTooltip text={isOrderCategory(item.category)
                                      ? t(OrderCategoriesTranslationMap[item.category as keyof typeof OrderCategoriesTranslationMap])
                                      : item.category} />
                                    {(showMessageWhenNoCost && item.price == 0) ?
                                      <TextWithOverflowTooltip text={`${t('noCostPriceMessage')}`} />
                                      :
                                      <TextWithOverflowTooltip text={applyRoundAndFormat(item.price, currencyCode)} />
                                    }
                                  </Grid>
                                  <Grid item
                                    xs={6}>
                                    <TextWithOverflowTooltip text={`${t('orderableUOM')} ${item.orderableUom} (${item.packSize}${item.primaryUom})`} />
                                    <TextWithOverflowTooltip text={`${t('primaryUOM')} ${item.primaryUom}`} />
                                    <Tooltip title={`${t('stockOnHand')}: ${item.stockOnHand}`}
                                      arrow>
                                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                        {t('stockOnHandABR')} {item.stockOnHand}
                                      </Box>
                                    </Tooltip>
                                    <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                                      arrow>
                                      <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                        {t('StockOnOrderABR')} {item.stockOnOrder}
                                      </Box>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              ) : (
                                <>
                                  {item.name}  <br />
                                  {item.sku}   <br />
                                  {isOrderCategory(item.category)
                                    ? t(OrderCategoriesTranslationMap[item.category as keyof typeof OrderCategoriesTranslationMap])
                                    : item.category} <br />
                                  {(showMessageWhenNoCost && item.price === 0) ?
                                    `${t('noCostPriceMessage')}` :
                                    applyRoundAndFormat(item.price, currencyCode)
                                  }
                                  <br />
                                  {t('orderableUOM')} {item.orderableUom} ({item.packSize}{item.primaryUom}) <br />
                                  {t('primaryUOM')} {item.primaryUom}  <br />
                                  <Tooltip title={`${t('stockOnHand')}: ${item.stockOnHand}`}
                                    arrow>
                                    <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                      {t('stockOnHandABR')} {item.stockOnHand}
                                    </Box>
                                  </Tooltip>
                                  <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                                    arrow>
                                    <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                      {t('StockOnOrderABR')} {item.stockOnOrder}
                                    </Box>
                                  </Tooltip>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="center"
                          sx={{ verticalAlign: 'middle' }}>
                          <TextField
                            size="small"
                            type="number"
                            value={itemQuantities[item.itemId] ?? item.quantity}
                            onChange={(e) =>
                              handleQuantityChange(item.itemId, parseInt(e.target.value))
                            }
                            disabled={
                              loading || orderDetails?.status !== OrderStatus.Editable
                            }
                            sx={{
                              maxWidth: 70,
                              input: {
                                textAlign: 'center',
                                padding: '3px',
                                fontSize: '12px',
                              },
                              paddingRight: '0px',
                            }}
                            InputProps={{
                              inputProps: { min: 1 },
                            }}
                          />
                        </TableCell>
                        <TableCell align="center"
                          sx={{ verticalAlign: 'middle' }}>{item.confirmedQuantity}</TableCell>
                        <TableCell align="center"
                          sx={{ verticalAlign: 'middle' }}>{item.shippedQuantity}</TableCell>
                        <TableCell align="center"
                          sx={{ verticalAlign: 'middle' }}>
                          {getTotalFormattedPrice(item, orderConfirmed!, currencyCode)}
                        </TableCell>
                        <TableCell align="center"
                          sx={{ verticalAlign: 'middle' }}>
                          <IconButton
                            color="error"
                            disabled={loading || orderDetails?.status !== OrderStatus.Editable}
                            onClick={() => handleOpenDialogForDeletion(item.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={dialogState.open}
        icon={<DeleteIcon />}
        title={dialogState.title}
        message={dialogState.message}
        confirmButtonText={t('confirm')}
        cancelButtonText={t('cancel')}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelDialog}
      />
      <CopyOrderModal
        currentOrderId={orderDetails?.id ?? 0}
        orderTypeId={orderDetails?.orderTypeId ?? 0}
        open={isCopyOrderModalOpen}
        onClose={() => setIsCopyOrderModalOpen(false)}
        onConfirm={handleOrderCopyConfirm}
      />
      <Snackbar open={snackbar.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <LeavePageModal
        open={blocker.state === 'blocked' && modifiedItems.size > 0}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onCancel={handleLeavePageModalCancel}
        onConfirmUrl='/store/order'
        leaveWarningContent={t('saveOnLeaveWarningContent')}
        leaveWarningTitle={t('saveOnLeaveWarningTitle')}
      />
    </PageArea >
  );
};

export default OrderDetailsPage;
