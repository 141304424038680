interface EnvironmentConfig {
    apiBaseUrl: string;
    ewsCompanyId: string;
    ewsApiKey: string;
    auth0BaseUrl: string;
    auth0ClientId: string;
    auth0Audience: string;
}

export const getApiConfig = (): EnvironmentConfig => {

  const localEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '12878',
    ewsApiKey: '728fb4f8-27af-425f-b753-222e76ec8f70',
    auth0BaseUrl: 'https://easywebstore-test.eu.auth0.com/oauth',
    auth0ClientId: '6xZ78H92Z6Kc5i948NtFBat1W1dOkg52',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  };

  const qaEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '12878',
    ewsApiKey: '728fb4f8-27af-425f-b753-222e76ec8f70',
    auth0BaseUrl: 'https://easywebstore-test.eu.auth0.com/oauth',
    auth0ClientId: '6xZ78H92Z6Kc5i948NtFBat1W1dOkg52',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  };

  const uatEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '12878',
    ewsApiKey: '728fb4f8-27af-425f-b753-222e76ec8f70',
    auth0BaseUrl: 'https://easywebstore-test.eu.auth0.com/oauth',
    auth0ClientId: '6xZ78H92Z6Kc5i948NtFBat1W1dOkg52',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  };

  const prodEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '13190',
    ewsApiKey: '86da15a9-19be-4380-bc3e-ef718ad20230',
    auth0BaseUrl: 'https://easywebstore-prod.eu.auth0.com/oauth',
    auth0ClientId: '8wPKByOptTCVoSTGCsMr6XfrdDl1uhbt',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  };

  const sdsUatEmeaEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '12878',
    ewsApiKey: '728fb4f8-27af-425f-b753-222e76ec8f70',
    auth0BaseUrl: 'https://easywebstore-test.eu.auth0.com/oauth',
    auth0ClientId: '6xZ78H92Z6Kc5i948NtFBat1W1dOkg52',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  };

  const uatLacEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '13479',
    ewsApiKey: 'e2850a69-35d2-4b01-91bf-461749d7c02a',
    auth0BaseUrl: 'https://orderly-inventory-us.us.auth0.com/oauth',
    auth0ClientId: 'Ytd5WFuna0qJortL1kFU96qzrzfN4GH6',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  };

  const sdsProdEmeaEnvironment: EnvironmentConfig = {
    apiBaseUrl: '/api',
    ewsCompanyId: '13190',
    ewsApiKey: '86da15a9-19be-4380-bc3e-ef718ad20230',
    auth0BaseUrl: 'https://easywebstore-prod.eu.auth0.com/oauth',
    auth0ClientId: '8wPKByOptTCVoSTGCsMr6XfrdDl1uhbt',
    auth0Audience: 'https://starbucks-inventory-api.orderly.eu',
  };

  switch (window.location.hostname) {
  case 'localhost':
    return localEnvironment;
  case 'sscm-test-emea-web.azurewebsites.net':
  case 'sscm-test-emea-web-v2.azurewebsites.net':
    return qaEnvironment;
  case 'orderly-sscm-uat-emea-app.azurewebsites.net':
  case 'app-uat.orderly.io':
    return uatEnvironment;
  case 'app.orderly.io'  :
    return prodEnvironment;
  case 'sds-uat.orderly.io':
    return sdsUatEmeaEnvironment;
  case 'sds.orderly.io':
  case 'sbux-sscm-prod-emea-app.azurewebsites.net':
    return sdsProdEmeaEnvironment;
  case 'orderly-sscm-uat-lac-app-ctekfjb7g2d5dcf7.westus2-01.azurewebsites.net':
  case 'orderly-sscm-uat-lac.orderly.io':
    return uatLacEnvironment;
  default:
    return localEnvironment;
  }
};
